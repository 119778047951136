<template>
  <Layout :padding="false">
    <div class="columns m-0" style="width: 100%">
      <div class="column is-2">
        <aside class="menu">
          <p class="menu-label">
            FORMS
          </p>
          <ul class="menu-list">
            <li><a :class="{'is-active': activeTab === 'userForms'}" @click="activeTab = 'userForms'">My forms</a></li>
            <li><a :class="{'is-active': activeTab === 'companyForms'}" @click="activeTab = 'companyForms'">Company forms</a></li>
            <li><a :class="{'is-active': activeTab === 'sharedForms'}" @click="activeTab = 'sharedForms'">Shared with me</a></li>
            <li v-if="isAdmin"><a :class="{'is-active': activeTab === 'allForms'}" @click="activeTab = 'allForms'">All Forms</a></li>
          </ul>
        </aside>
      </div>
      <div class="column is-10">
        <form-list :targetList="targetList" />
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  name: 'Forms',
  components: {
    Layout: () => import('@/router/layouts/Main'),
    FormList: () => import('@/components/forms/FormList'),
  },
  data() {
    return {
      user: $cookies.get($formsConfig.core.cookieName),
      activeTab: 'userForms',
    };
  },
  computed: {
    targetList() {
      return this.activeTab;
    },
    isAdmin() {
      return this.user.role === `admin`;
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
  .menu-list a.is-active, .menu-list a.is-active:hover {
    background-color: #3e4a60;
    color: #fff;
  }
  .menu-list a:hover {
    background-color: #f5f5f5;
    color: #363636;
  }
</style>
